import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  organizations: [],
  defaultOrg: "",
  provider: [],
  providers: [
    { provider: "propella", provider_type: "Suss Ads" },
    // { provider: "adcash", provider_type: "Suss Market Place" },
  ],
  status: "loading",
};

export const getOrganizations = createAsyncThunk(
  "organizations/getOrganizations",

  async (payload) => {
    localStorage.setItem("token", payload.accessToken.token);
    return await axios
      .get(payload.organizations_url, {
        headers: {
          Authorization: `Bearer ` + payload.accessToken?.token,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      });
  }
);

export const createOrganization = createAsyncThunk(
  "organizations/createOrganization",
  async (payload) => {
    return await axios
      .put(
        process.env.REACT_APP_API_URL + "/organization",
        {
          name: payload.name,
          contactInfo: {
            address: payload.address,
            phoneNumbers: [
              payload.primaryPhoneNumber,
              payload.secondaryPhoneNumber,
            ],
            city: payload.city,
          },
          orgConfigs: {
            propella: {
              apiKey: payload.propellerApiKey,
              multipliers: {
                ctr: payload.propellerctr,
                spends: payload.propellerspends,
                cpm: payload.propellercpm,
              },
            },
            eskimi: {
              apiKey: payload.eskimiApiKey,
              multipliers: {
                ctr: payload.eskimictr,
                spends: payload.eskimispends,
                cpm: payload.eskimicpm,
              },
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ` + payload.accessToken,
          },
        }
      )
      .then((response) => {})
      .catch((error) => {
        console.log({ error });
      });
  }
);

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,

  reducers: {
    setOrganizations: (state, payload) => {
      state.organizations = [payload];
    },
    switchOrganization: (state, action) => {
      return {
        ...state,
        defaultOrgId: action.payload.orgId,
        defaultOrg: action.payload.orgName,
      };
    },
  },

  extraReducers: {
    //Get organizations
    [getOrganizations.pending]: (state, action) => {
      state.status = "loading";
    },

    [getOrganizations.fulfilled]: (state, action) => {
      state.provider = ["propella"];
      // state.provider = ["propella", "adcash"];
      // state.provider = [{ propella: "Suss Ads", adcash: "Suss Market Place" }];
      // state.providers = Object.keys(action?.payload?.data?.value[0]?.configs);
      state.defaultOrgId = action?.payload?.data?.value?.userOrgs[0]?.id;
      state.defaultOrg = action?.payload?.data?.value[0]?.userOrgs?.name;
      state.organizations = action?.payload?.data?.value?.userOrgs;
      state.status = "success";
    },

    [getOrganizations.rejected]: (state, action) => {
      state.status = "failed";
    },

    //Create organizations
    [createOrganization.pending]: (state, action) => {
      state.status = "loading";
    },

    [createOrganization.fulfilled]: (state, action) => {
      state.status = "success";
    },

    [createOrganization.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

const { actions: organizationsActions, reducer: organizationsReducer } =
  organizationsSlice;

export { organizationsActions, organizationsReducer };
