import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useAuth from "../../hooks/useAuth";

const UsersList = () => {
  const { accessToken } = useAuth();
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, {
        headers: {
          ContentType: "application/x-www-form-urlencoded",
        },
        body: {
          client_id: "oGL4T1MmkKiyCoswRqJXdzMNnZdzklBe",
          client_secret:
            "_5-X1NUpvjLZ5wPiX-tkJ2BTefuVQ9j2xJc-OEhe1axlgf51MZc_yilxRH40KkFD",
          audience: "https://suss.us.auth0.com/api/v2",
          grant_type: "client_credentials",
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error("ERROR: ", error);
      });
    // axios
    //   .get(`${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users`, {
    //     params: {
    //       client_id: "oGL4T1MmkKiyCoswRqJXdzMNnZdzklBe",
    //       client_secret:
    //         "_5-X1NUpvjLZ5wPiX-tkJ2BTefuVQ9j2xJc-OEhe1axlgf51MZc_yilxRH40KkFD",
    //       audience: "https://suss.us.auth0.com/api/v2/",
    //       grant_type: "client_credentials",
    //       q: "",
    //       search_engine: "v2",
    //     },
    //     headers: {
    //       Authorization: `Bearer ` + accessToken.token,
    //     },
    //   })
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     console.error("ERROR: ", error);
    //   });
  });

  return (
    <>
      <Helmet title="Users List" />

      <Typography variant="h3" gutterBottom display="inline">
        Users List
      </Typography>

      <Divider my={6} />

      <Grid container my={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardHeader
              action={
                <>
                  {/* <Button
              variant="contained"
              disableElevation
              ml={6}
              color="secondary"
            >
              New campaign
            </Button> */}
                </>
              }
              title="Users"
            />
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Business name</TableCell>
                      {/* <TableCell align="left">Status</TableCell> */}
                      <TableCell align="center">Spends</TableCell>
                      <TableCell align="center">CTR</TableCell>
                      <TableCell align="center">CPM</TableCell>
                      <TableCell align="left">Address</TableCell>
                      <TableCell align="left">City</TableCell>
                      <TableCell align="left">Status</TableCell>
                      {/* <TableCell align="left"></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {organizations.map((organization, organizationIdx) => (
                      <TableRow key={organization.id}>
                        <TableCell align="left">{organization.name}</TableCell>
                        <TableCell align="center">
                          {organization.configs.propella.multipliers.spends}
                        </TableCell>
                        <TableCell align="center">
                          {organization.configs.propella.multipliers.ctr}
                        </TableCell>
                        <TableCell align="center">
                          {organization.configs.propella.multipliers.cpm}
                        </TableCell>
                        <TableCell align="left">
                          {organization.contactInfo.address}
                        </TableCell>
                        <TableCell align="left">
                          {organization.contactInfo.city}
                        </TableCell>
                        <TableCell align="left">
                          {organization.status ? (
                            <Chip
                              label="Active"
                              color="success"
                              variant="outlined"
                            />
                          ) : (
                            <Chip
                              label="Inactive"
                              color="error"
                              variant="outlined"
                            />
                          )}
                        </TableCell>
                        {/* <TableCell padding="none" align="right">
                          <Box mr={2}>
                            <IconButton aria-label="delete" size="large">
                              <EditIcon />
                            </IconButton>
                            <IconButton aria-label="details" size="large">
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell> *
                      </TableRow>
                    ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UsersList;
