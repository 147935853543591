import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { format, subDays } from "date-fns";

const dateToday = new Date();
const dayTo = format(dateToday, "yyyy-MM-dd");

const result = subDays(new Date(), 7);

const dayFrom = format(result, "yyyy-MM-dd");

const initialState = {
  campaigns: [],
  dayTo: dayTo,
  dayFrom: dayFrom,
  status: "loading",
};

export const getCampaigns = createAsyncThunk(
  "campaigns/getCampaigns",
  async (payload, { getState }) => {
    const { accessToken } = useAuth();
    const state = getState().organizations;
    return await axios
      .get(
        `${process.env.REACT_APP_API_URL}/CampaignStats/organization/${state.defaultOrgId}/${payload.provider}`,
        {
          params: {
            GroupBy: "campaign",
            DayFrom: dayFrom,
            DayTo: dayTo,
          },
          headers: {
            Authorization: `Bearer ` + accessToken.token,
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      });
  }
);

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,

  reducers: {
    setCampaigns: (state, payload) => {
      state.campaigns = payload.payload.data.value;
    },
    setDateRange: (state, action) => {
      return {
        ...state,
        dayFrom: action.payload.dayFrom,
        dayTo: action.payload.dayTo,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        status: action,
      };
    },
  },

  extraReducers: {
    [getCampaigns.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCampaigns.fulfilled]: (state, action) => {
      state.campaigns = action?.payload?.data?.value;
      state.status = "success";
    },
    [getCampaigns.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

const { actions: campaignsActions, reducer: campaignsReducer } = campaignsSlice;

export { campaignsActions, campaignsReducer };
