import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Tooltip,
  Menu,
  MenuItem,
  Avatar,
  Badge,
  Grid,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { getOrganizations } from "../../redux/slices/organizationsSlice";
import { getCampaigns } from "../../redux/slices/campaignsSlice";
// import { getCampaigns } from "../../redux/slices/campaignsSlice";
import { organizationsActions } from "../../redux/slices/organizationsSlice";
import { format, subDays } from "date-fns";

const dateToday = new Date();
// const dateWeekAgo = subDays(dateToday, 7);
// const result = subDays(new Date(2014, 8, 1), 10);
const dayTo = format(dateToday, "yyyy-MM-dd");

var weekStart = new Date();
const result = subDays(new Date(), 7);
weekStart.setDate(weekStart.getDate() - weekStart.getDay());

const dayFrom = format(result, "yyyy-MM-dd");

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const organizations = useSelector((state) => state.organizations);

  // const [campaignsConfig, setCampaignsPayload] = useState({
  //   currentOrgId: organizations.currentOrg.id,
  //   currentOrg: organizations.currentOrg.name,
  //   groupBy: "campaigns",
  //   provider: organizations.providers[0]?.id,
  //   dayFrom: dayFrom,
  //   dayTo: dayTo,
  // });

  const dispatch = useDispatch();
  const { user, accessToken, signOut } = useAuth();
  const org_endpoint = "/access/" + user.email;
  const organizations_lists_url = process.env.REACT_APP_API_URL + org_endpoint;

  const navigate = useNavigate();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = async () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    const payload = {
      organizations_url: organizations_lists_url,
      accessToken: accessToken,
    };

    dispatch(getOrganizations(payload));
  }, [dispatch]);

  const selectDefaultOrgId = organizations.id;
  // setCampaignsPayload({
  //   defaultOrgId: organizations.defaultOrgId,
  //   defaultOrg: organizations.defaultOrg,
  //   groupBy: "campaigns",
  //   provider: organizations.provider[0],
  //   dayFrom: dayFrom,
  //   dayTo: dayTo,
  // });

  if (organizations.status === "success") {
    const campaignsPayload = {
      defaultOrgId: organizations.defaultOrgId,
      defaultOrg: organizations.defaultOrg,
      groupBy: "campaigns",
      provider: organizations.provider[0],
      dayFrom: dayFrom,
      dayTo: dayTo,
    };
    dispatch(getCampaigns(campaignsPayload));
  }

  return (
    <React.Fragment>
      <Grid container spacing={6} alignItems={"center"}>
        <Grid item sx={{ minWidth: 250 }}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="organization-select-label">Organization</InputLabel>
            <Select
              labelId="organization-select-label"
              id="organization-select"
              value={organizations.defaultOrgId}
              defaultValue={organizations.defaultOrgId}
              label="Organization"
              onChange={(event) => {
                const orgDetails = {
                  orgId: event.target.value,
                  orgName: document.getElementById("organization-select")
                    .innerHTML,
                };
                dispatch(organizationsActions.switchOrganization(orgDetails));
              }}
            >
              {organizations.organizations.map(
                (organization, organizationIdx) => (
                  <MenuItem value={organization.id} key={organizationIdx}>
                    {organization.name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Tooltip title="Account">
            <FooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
              color="success"
              aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={toggleMenu}
            >
              {!!user && <Avatar alt={user.displayName} src={user.avatar} />}
            </FooterBadge>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorMenu}
            open={Boolean(anchorMenu)}
            onClose={closeMenu}
          >
            {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
