import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Alert,
  Autocomplete,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createOrganization } from "../../redux/slices/organizationsSlice";
import useAuth from "../../hooks/useAuth";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import * as yup from "yup";
import axios from "axios";
import { useAutocomplete, AutocompleteGetTagProps } from "@mui/base";
import { Check, Close } from "@mui/icons-material";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const Transition = (props) => {
  return <Slide {...props} direction="up" />;
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const UserSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Users email is required"),
  organizations: yup.array().required("Users organizations is required"),
});

const UserDetails = () => {
  const organizations = useSelector((state) => state.organizations);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const dispatch = useDispatch();
  const { accessToken } = useAuth();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/organization/list`, {
        headers: {
          Authorization: `Bearer ` + accessToken.token,
        },
      })
      .then((response) => {
        setOrganizationList(response.data.value);
        setLoading(false);
      })
      .catch((error) => {
        console.error("ERROR: ", error);
        setLoading(false);
      });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid container mb={6}>
        <Grid item xs={12}>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            TransitionComponent={Transition}
            onClose={handleClose}
            message="User created successfully."
            action={action}
          />

          <Card>
            <CardContent>
              <Formik
                validationSchema={UserSchema}
                validateOnChange={false}
                validateOnBlur={true}
                initialValues={{
                  email: "",
                  organizations: "",
                }}
                onSubmit={(values, actions) => {
                  setLoading(true);
                  const user = {
                    userId: values.email,
                    orgIds: values.organizations.map((orgs) => orgs.id),
                  };
                  axios
                    .post(`${process.env.REACT_APP_API_URL}/access`, user, {
                      headers: {
                        Authorization: `Bearer ` + accessToken.token,
                        ContentType: "application/json",
                      },
                    })
                    .then((response) => {
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.error("ERROR: ", error);
                      setLoading(false);
                    });
                }}
              >
                {(props) => (
                  <Form noValidate>
                    <Typography variant="h6" gutterBottom>
                      User details
                    </Typography>

                    <Grid container spacing={8}>
                      <Grid item md={4}>
                        <TextField
                          id="email"
                          error={props.errors.email}
                          helperText={props.errors.email}
                          label="User's email address"
                          onChange={props.handleChange("email")}
                          value={props.values.email}
                          variant="outlined"
                          required
                          fullWidth
                          my={2}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <Autocomplete
                          id="organizations"
                          multiple
                          limitTags={2}
                          id="multiple-limit-tags"
                          options={organizationList}
                          onChange={(e, value) => {
                            props.setFieldValue("organizations", value);
                          }}
                          // defaultValue={organizationList[0].name}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="organizations"
                              error={props.errors.organizations}
                              helperText={props.errors.organizations}
                              label="Select organization"
                              variant="outlined"
                              value={props.values.organizations}
                              required
                              fullWidth
                              my={2}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disableElevation
                      disabled={loading ? true : false}
                    >
                      Create user
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

function CreateUser() {
  return (
    <React.Fragment>
      <Helmet title="Create user" />

      <Typography variant="h3" gutterBottom display="inline">
        Create user
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Settings</Typography>
      </Breadcrumbs> */}

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UserDetails />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateUser;
