import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Alert,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Snackbar,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createOrganization } from "../../redux/slices/organizationsSlice";
import useAuth from "../../hooks/useAuth";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import * as yup from "yup";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const Transition = (props) => {
  return <Slide {...props} direction="up" />;
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const BusinessSchema = yup.object({
  name: yup.string().required("Company name is required"),
  address: yup.string().required("Company address is required"),
  primaryPhoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Try +123 456 789 012"),
  secondaryPhoneNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid"),
  city: yup.string(),
  propellerApiKey: yup.string(),
  propellerctr: yup.number(),
  propellerspends: yup.number(),
  propellercpm: yup.number(),
  eskimiApiKey: yup.string(),
  eskimictr: yup.number(),
  eskimispends: yup.number(),
  eskimicpm: yup.number(),
});

const BusinessDetails = () => {
  const organizations = useSelector((state) => state.organizations);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { accessToken } = useAuth();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid container mb={6}>
        <Grid item xs={12}>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            TransitionComponent={Transition}
            onClose={handleClose}
            message="Organization created successfully."
            action={action}
          />

          <Card>
            <CardContent>
              <Formik
                validationSchema={BusinessSchema}
                initialValues={{
                  name: "",
                  address: "",
                  primaryPhoneNumber: "",
                  secondaryPhoneNumber: "",
                  city: "",
                  propellerApiKey: "",
                  propellerctr: "",
                  propellerspends: "",
                  propellercpm: "",
                  eskimiApiKey: "",
                  eskimictr: "",
                  eskimispends: "",
                  eskimicpm: "",
                  accessToken: accessToken.token,
                }}
                onSubmit={(values, actions) => {
                  const organization = async () => {
                    return await dispatch(createOrganization(values));
                  };
                  organization()
                    .then((response) => {
                      actions.resetForm();
                      setOpen(true);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                {(props) => (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      Business details
                    </Typography>

                    <Grid container spacing={8}>
                      <Grid item md={4}>
                        <TextField
                          id="name"
                          error={props.errors.name}
                          helperText={props.errors.name}
                          label="Company name"
                          onChange={props.handleChange("name")}
                          value={props.values.name}
                          variant="outlined"
                          required
                          fullWidth
                          my={2}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          id="primary_phone_number"
                          error={props.errors.primaryPhoneNumber}
                          helperText={props.errors.primaryPhoneNumber}
                          label="Primary phone number"
                          onChange={props.handleChange("primaryPhoneNumber")}
                          value={props.values.primaryPhoneNumber}
                          variant="outlined"
                          required
                          fullWidth
                          my={2}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          id="secondary_phone_number"
                          error={props.errors.secondaryPhoneNumber}
                          helperText={props.errors.secondaryPhoneNumber}
                          label="Secondary phone number"
                          onChange={props.handleChange("secondaryPhoneNumber")}
                          value={props.values.secondaryPhoneNumber}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid item md={4}>
                        <TextField
                          id="address"
                          error={props.errors.address}
                          helperText={props.errors.address}
                          label="Address"
                          onChange={props.handleChange("address")}
                          value={props.values.address}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          id="city"
                          error={props.errors.city}
                          helperText={props.errors.city}
                          label="City"
                          onChange={props.handleChange("city")}
                          value={props.values.city}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <Divider my={6} />
                    <Typography variant="h6" gutterBottom>
                      Suss Ads configurations
                    </Typography>

                    <Grid container spacing={6}>
                      <Grid item md={3}>
                        <TextField
                          id="propeller_api_key"
                          error={props.errors.propellerApiKey}
                          helperText={props.errors.propellerApiKey}
                          label="API key"
                          onChange={props.handleChange("propellerApiKey")}
                          value={props.values.propellerApiKey}
                          variant="outlined"
                          fullWidth
                          required
                          my={2}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="propeller_ctr"
                          error={props.errors.propellerctr}
                          helperText={props.errors.propellerctr}
                          label="CTR"
                          onChange={props.handleChange("propellerctr")}
                          value={props.values.propellerctr}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="propeller_spends"
                          error={props.errors.propellerspends}
                          helperText={props.errors.propellerspends}
                          label="Spends"
                          onChange={props.handleChange("propellerspends")}
                          value={props.values.propellerspends}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="propeller_cpm"
                          error={props.errors.propellercpm}
                          helperText={props.errors.propellercpm}
                          label="CPM"
                          onChange={props.handleChange("propellercpm")}
                          value={props.values.propellercpm}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <Divider my={8} />
                    <Typography variant="h6" gutterBottom>
                      Interactive Display and Video Ads
                    </Typography>

                    <Grid container spacing={6}>
                      <Grid item md={3}>
                        <TextField
                          id="eskimi_api_key"
                          label="API key"
                          onChange={props.handleChange("eskimiApiKey")}
                          value={props.values.eskimiApiKey}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="eskimi_ctr"
                          label="CTR"
                          onChange={props.handleChange("eskimictr")}
                          value={props.values.eskimictr}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="eskimi_spends"
                          label="Spends"
                          onChange={props.handleChange("eskimispends")}
                          value={props.values.eskimispends}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          id="eskimi_cpm"
                          label="CPM"
                          onChange={props.handleChange("eskimicpm")}
                          value={props.values.eskimicpm}
                          variant="outlined"
                          fullWidth
                          my={2}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        organizations.status === "loading" ? true : false
                      }
                      mt={3}
                      onClick={props.handleSubmit}
                    >
                      Create business
                    </Button>
                  </React.Fragment>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

function CreateBusiness() {
  return (
    <React.Fragment>
      <Helmet title="Create business" />

      <Typography variant="h3" gutterBottom display="inline">
        Create business
      </Typography>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Settings</Typography>
      </Breadcrumbs> */}

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <BusinessDetails />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateBusiness;
