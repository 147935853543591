// import { Business } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import { Briefcase, Sliders, TrendingUp, User } from "react-feather";

const show = false;

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
  },
  {
    href: "/campaigns",
    icon: TrendingUp,
    title: "Campaigns",
    children: [
      {
        href: "/campaigns/campaigns-list",
        title: "View Campaigns",
      },
    ],
  },
  {
    href: "/organizations",
    icon: Briefcase,
    title: "Organizations",
    children: [
      {
        href: "/organizations/organizations-list",
        title: "View Organizations",
      },
      {
        href: "/organizations/create-organization",
        title: "Create Organization",
      },
    ],
  },
  {
    href: "/users",
    icon: User,
    title: "Users",
    children: [
      // {
      //   href: "/users/users-list",
      //   title: "View Users",
      // },
      {
        href: "/users/create-user",
        title: "Create User",
      },
    ],
  },
];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
