import { configureStore } from "@reduxjs/toolkit";
import { campaignsReducer } from "./slices/campaignsSlice";
import { organizationsReducer } from "./slices/organizationsSlice";

export const store = configureStore({
  reducer: {
    campaigns: campaignsReducer,
    organizations: organizationsReducer,
  },
});
