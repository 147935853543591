import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader,
  Chip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { useSelector } from "react-redux";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import axios from "axios";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function BusinessList() {
  const [organizations, setOrganizations] = useState([]);
  console.log(organizations);
  const { accessToken } = useAuth();
  const organizations_lists_url =
    process.env.REACT_APP_API_URL + "/organization/list";

  useEffect(() => {
    axios
      .get(organizations_lists_url, {
        headers: {
          Authorization: `Bearer ` + accessToken?.token,
        },
      })
      .then((response) => {
        const organizations = response.data.value;
        setOrganizations(organizations);
      })
      .catch((error) => {
        console.error();
      });
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Organizations List" />

      <Typography variant="h3" gutterBottom display="inline">
        Business List
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/dashboard">
          Dashboard
        </Link>
        <Typography>Campaigns</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardHeader
              action={
                <React.Fragment>
                  {/* <Button
              variant="contained"
              disableElevation
              ml={6}
              color="secondary"
            >
              New campaign
            </Button> */}
                </React.Fragment>
              }
              title="Businesses"
            />
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Business name</TableCell>
                      {/* <TableCell align="left">Status</TableCell> */}
                      <TableCell align="center">Spends</TableCell>
                      <TableCell align="center">CTR</TableCell>
                      <TableCell align="center">CPM</TableCell>
                      <TableCell align="left">Address</TableCell>
                      <TableCell align="left">City</TableCell>
                      <TableCell align="left">Status</TableCell>
                      {/* <TableCell align="left"></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {organizations.map((organization, organizationIdx) => (
                      <TableRow key={organization.id}>
                        <TableCell align="left">{organization.name}</TableCell>
                        <TableCell align="center">
                          {organization.configs?.propella.multipliers.spends}
                        </TableCell>
                        <TableCell align="center">
                          {organization.configs?.propella.multipliers.ctr}
                        </TableCell>
                        <TableCell align="center">
                          {organization.configs?.propella.multipliers.cpm}
                        </TableCell>
                        <TableCell align="left">
                          {organization.contactInfo.address}
                        </TableCell>
                        <TableCell align="left">
                          {organization.contactInfo.city}
                        </TableCell>
                        <TableCell align="left">
                          {organization.status ? (
                            <Chip
                              label="Active"
                              color="success"
                              variant="outlined"
                            />
                          ) : (
                            <Chip
                              label="Inactive"
                              color="error"
                              variant="outlined"
                            />
                          )}
                        </TableCell>
                        {/* <TableCell padding="none" align="right">
                          <Box mr={2}>
                            <IconButton aria-label="delete" size="large">
                              <EditIcon />
                            </IconButton>
                            <IconButton aria-label="details" size="large">
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default BusinessList;
