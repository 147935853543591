import React from "react";
import styled from "styled-components/macro";

import { Typography } from "@mui/material";

import SidebarNavList from "./SidebarNavList";
import jwtDecode from "jwt-decode";

const storedToken = localStorage.getItem("token");

const canReadCampaigns = Boolean;

const canCreateOrganizations = Boolean;
const canReadOrganizations = Boolean;

const canReadUseraccess = Boolean;
const canCreateUseraccess = Boolean;

if (storedToken) {
  const token = jwtDecode(storedToken);

  const canCreateCampaigns = token.permissions.find(
    (permission) => permission == "create:campaigns"
  )
    ? true
    : false;

  const canCreateOrganizations = token.permissions.find(
    (permission) => permission == "create:organizations"
  )
    ? true
    : false;

  const canCreateUseraccess = token.permissions.find(
    (permission) => permission == "create:useraccess"
  )
    ? true
    : false;

  const canReadCampaigns = token.permissions.find(
    (permission) => permission == "read:campaigns"
  )
    ? true
    : false;

  const canReadOrganizations = token.permissions.find(
    (permission) => permission == "read:organizations"
  )
    ? true
    : false;

  const canReadUserOrganizationAccess = token.permissions.find(
    (permission) => permission == "read:userorganizationaccess"
  )
    ? true
    : false;
}

const Title = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;

const SidebarNavSection = (props) => {
  const {
    title,
    pages,
    className,
    component: Component = "nav",
    ...rest
  } = props;

  pages.forEach((page, index) => {
    //Campaigns Links
    if (page.href === "/campaigns") {
      if (!canReadCampaigns) {
        pages.splice(index, 1);
      }
    }
    //Campaigns Child Links
    if (page.href === "/campaigns") {
      page.children.forEach((childPage, childIndex) => {
        if (
          childPage.href === "/campaigns/campaigns-list" &&
          !canReadCampaigns
        ) {
          pages[index].children.splice(childIndex, 1);
        }
      });
    }

    //Business Links
    if (page.href === "/business") {
      if (!canCreateOrganizations && !canReadOrganizations) {
        pages.splice(index, 1);
      }
    }

    //Business Child Links
    if (page.href === "/business") {
      page.children.forEach((childPage, childIndex) => {
        if (
          childPage.href === "/business/business-list" &&
          !canReadOrganizations
        ) {
          pages[index].children.splice(childIndex, 1);
        }
        if (
          childPage.href === "/business/create-business" &&
          !canCreateOrganizations
        ) {
          pages[index].children.splice(childIndex, 1);
        }
      });
    }

    //Users Links
    if (page.href === "/users") {
      if (!canCreateUseraccess && !canReadUseraccess) {
        pages.splice(index, 1);
      }
    }

    //Users Child Links
    if (page.href === "/users") {
      page.children.forEach((childPage, childIndex) => {
        if (childPage.href === "/users/users-list" && !canReadOrganizations) {
          pages[index].children.splice(childIndex, 1);
        }
        if (childPage.href === "/users/create-users" && !canCreateUseraccess) {
          pages[index].children.splice(childIndex, 1);
        }
      });
    }
  });

  return (
    <Component {...rest}>
      {title && <Title variant="subtitle2">{title}</Title>}
      <SidebarNavList pages={pages} depth={0} />
    </Component>
  );
};

export default SidebarNavSection;
