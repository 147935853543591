import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  CardMedia,
  Chip,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { Box, spacing } from "@mui/system";
import { useSelector } from "react-redux";

const TextField = styled(MuiTextField)(spacing);
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

function CampaignDetails() {
  const { id } = useParams();
  const [campaignDetails, setCampaignDetials] = useState({});
  const organization = useSelector((state) => state.organizations);
  const { accessToken } = useAuth();
  const provider = organization.provider;
  const organizationId = organization.defaultOrgId;
  const campaign_details_url =
    process.env.REACT_APP_API_URL +
    `/Campaign/organization/` +
    organizationId +
    `/` +
    provider +
    `/`;

  const getCampaignDetails = async () => {
    const { data } = await axios.get(campaign_details_url + id, {
      headers: {
        Authorization: `Bearer ` + accessToken.token,
      },
    });
    return data;
  };

  useEffect(() => {
    getCampaignDetails()
      .then((data) => {
        setCampaignDetials(data.value);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const CampaignSummary = () => {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom mb={6}>
          {campaignDetails.name?.charAt(0).toUpperCase()}
          {campaignDetails.name?.toLowerCase().slice(1).replace(/_/g, " ")}
        </Typography>
        <Card mb={6}>
          <CardContent>
            <Grid container spacing={6}>
              <Grid item md={4}>
                <TextField
                  label="Starting at"
                  defaultValue={campaignDetails.startedAt}
                  fullWidth
                  my={2}
                  disabled
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Capping"
                  defaultValue={campaignDetails.capping}
                  fullWidth
                  my={2}
                  disabled
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Frequency"
                  defaultValue={campaignDetails.frequency}
                  fullWidth
                  my={2}
                  disabled
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Campaign type"
                  variant="outlined"
                  defaultValue=""
                  fullWidth
                  my={2}
                  disabled
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Total limit"
                  variant="outlined"
                  defaultValue={campaignDetails.limitTotalAmount * 2}
                  fullWidth
                  my={2}
                  disabled
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Campaign status"
                  variant="outlined"
                  defaultValue={campaignDetails.status}
                  fullWidth
                  my={2}
                  disabled
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Buy type"
                  variant="outlined"
                  defaultValue={campaignDetails.rateModel}
                  fullWidth
                  my={2}
                  disabled
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Daily limit"
                  variant="outlined"
                  defaultValue={campaignDetails.limitDailyAmount * 2}
                  fullWidth
                  my={2}
                  disabled
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label="Campaign archive"
                  variant="outlined"
                  defaultValue={campaignDetails.isArchived}
                  fullWidth
                  my={2}
                  disabled
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  const CampaignCreatives = () => {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom mb={6}>
          Creatives
        </Typography>
        <Grid container spacing={6}>
          {campaignDetails.creatives?.map((creative, creativeIdx) => (
            <Grid item md={4} key={creativeIdx}>
              <Card mb={6}>
                <Card sx={{ display: "flex" }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 152 }}
                    image={
                      creative?.image == null
                        ? "../assets/img/suss-ads-logo.png"
                        : creative?.image
                    }
                    alt={creative?.description}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "0 0 auto" }}>
                      <Typography component="div" variant="h5">
                        {creative?.title}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                      >
                        {creative?.description}
                      </Typography>
                      {creative?.userStatus ? (
                        <Chip
                          color="success"
                          label="Active"
                          variant="outlined"
                          size="small"
                        />
                      ) : (
                        <Chip
                          color="error"
                          label="Inactive"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </CardContent>
                  </Box>
                </Card>
              </Card>
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Campaign Details" />
      <Typography variant="h3" gutterBottom display="inline">
        Campaign Details
      </Typography>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CampaignSummary />
          <CampaignCreatives />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CampaignDetails;
