import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import SussLogo from "../../assets/img/suss-ads-logo-original.png";
// import SussLogoWhite from "../../assets/img/Suss-ads-white-logo.png";

import { Drawer as MuiDrawer, ListItemButton } from "@mui/material";

// import { ReactComponent as Logo } from "../../../public/static/img/organizations/";
// import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

// const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.palette.common.white};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 50px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.common.white};
  }
`;

// const BrandIcon = styled(Logo)`
//   margin-right: ${(props) => props.theme.spacing(2)};
//   color: ${(props) => props.theme.sidebar.header.brand.color};
//   fill: ${(props) => props.theme.sidebar.header.brand.color};
//   width: 32px;
//   height: 32px;
// `;

// const BrandChip = styled(Chip)`
//   background-color: ${yellow[700]};
//   border-radius: 5px;
//   color: ${(props) => props.theme.palette.common.white};
//   font-size: 55%;
//   height: 18px;
//   margin-left: 2px;
//   margin-top: -16px;
//   padding: 3px 0;

//   span {
//     padding-left: ${(props) => props.theme.spacing(1.375)};
//     padding-right: ${(props) => props.theme.spacing(1.375)};
//   }
// `;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/dashboard">
        {/* <BrandIcon />{" "} */}
        <img src={SussLogo} width="80%" alt="Suss Ads" />
        {/* <BrandChip label="Premium" /> */}
      </Brand>
      <SidebarNav items={items} />
      {/* {!!showFooter && <Footer />} */}
    </Drawer>
  );
};

export default Sidebar;
