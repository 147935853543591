import React, { useRef } from "react";
import styled from "styled-components/macro";
import {
  Download as DownloadIcon,
  // CopyAll as CopyAllIcon,
} from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { useSelector } from "react-redux";
import {
  Button,
  Card as MuiCard,
  CardHeader,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";

const Card = styled(MuiCard)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

function DashboardTable() {
  const campaigns = useSelector((state) => state.campaigns);
  const campaignsRef = useRef(null);

  const cleanCampaignName = (campaign) => {
    const cleanName = campaign
      ? campaign.toLowerCase().replace(/\||/g, "")
      : "";
    return cleanName.charAt(0).toUpperCase() + cleanName.slice(1);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getImpressions = (spends, spendsMultiplier, cpmMultiplier, cpm) => {
    const newCpm = cpm * (cpmMultiplier / 2);
    const impressions = ((spends * spendsMultiplier) / newCpm) * 1000;
    if (!Number.isFinite(impressions)) {
      return 0;
    }
    return parseInt(impressions).toLocaleString();
  };

  const getClicks = (clicks, ctrMultiplier) => {
    const clicks_calc = clicks * ctrMultiplier;
    return parseInt(clicks_calc).toLocaleString();
  };

  const getSpends = (spent, spendsMultiplier) => {
    const spends = spent * spendsMultiplier;
    return spends.toFixed(2);
  };

  const getCpm = (
    campaign_cpm,
    cpmMultiplier,
    clicks,
    ctrMultiplier,
    spends,
    spendsMultiplier,
    impressions
  ) => {
    let cpm: number;
    let checked_impression = impressions === undefined ? 0 : impressions;
    if (
      clicks > checked_impression ||
      getImpressions(spends, spendsMultiplier, cpmMultiplier, campaign_cpm) == 0
    ) {
      cpm = 0;
    } else {
      cpm = campaign_cpm * (cpmMultiplier / 2);
    }
    return cpm.toFixed(2);
  };

  const getCpc = (spent, spendsMultiplier, clicks, ctrMultiplier) => {
    const spends = spent * spendsMultiplier;
    const clicks_calc = clicks * ctrMultiplier;
    const cpc = spends / clicks_calc;
    if (!Number.isFinite(cpc)) {
      return parseFloat(0).toFixed(2);
    }
    return cpc.toFixed(2);
  };

  const getCtr = (
    clicks,
    spent,
    spendsMultiplier,
    cpm,
    ctrMultiplier,
    cpmMultiplier
  ) => {
    // get cpm multiplier to factor the right
    const impressions =
      ((spent * spendsMultiplier) / (cpm * (cpmMultiplier / 2))) * 1000;
    const clicks_calc = clicks * ctrMultiplier;

    const ctr = (clicks_calc / impressions) * 100;

    if (clicks_calc > impressions || !Number.isFinite(ctr)) {
      return parseFloat(0).toFixed(2);
    }
    return ctr.toFixed(2);
  };

  const campaignData = campaigns?.campaigns?.stats?.map((campaign) => {
    return [
      campaign.id,
      cleanCampaignName(campaign.name),
      getImpressions(
        campaign.spent,
        campaigns.campaigns.multipliers.spends,
        campaigns.campaigns.multipliers.cpm,
        campaign.cpm
      ),
      getClicks(campaign.clicks, campaigns.campaigns.multipliers.ctr),
      getSpends(campaign.spent, campaigns.campaigns.multipliers.spends),
      getCpm(
        campaign.cpm,
        campaigns.campaigns.multipliers.cpm,
        campaign.clicks,
        campaigns.campaigns.multipliers.ctr,
        campaign.spent,
        campaigns.campaigns.multipliers.spends,
        campaign.impressions
      ),
      getCpc(
        campaign.spent,
        campaigns.campaigns.multipliers.spends,
        campaign.clicks,
        campaigns.campaigns.multipliers.ctr
      ),
      getCtr(
        campaign.clicks,
        campaign.spent,
        campaigns.campaigns.multipliers.spends,
        campaign.cpm,
        campaigns.campaigns.multipliers.ctr,
        campaigns.campaigns.multipliers.cpm
      ),
      campaign.conversions,
      campaign.cr,
    ];
  });

  const headers = [
    "Campaign ID",
    "Campaign Name",
    "Impressions",
    "Clicks",
    "Spends ($)",
    "CPM ($)",
    "CPC ($)",
    "CTR (%)",
    "Conversions",
    "CR",
  ];

  const csv_report = {
    filename:
      "Campaigns report from " +
      moment(new Date(campaigns.dayFrom)).format("LL") +
      " to " +
      moment(new Date(campaigns.dayTo)).format("LL"),
    headers: headers ? Object.values(headers) : [],
    data: campaignData
      ? campaignData.map((campaign) => Object.values(campaign))
      : [],
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <React.Fragment>
            <Tooltip title="Download CSV" arrow>
              <SmallButton size="small" mr={2}>
                <CSVLink {...csv_report}>
                  <DownloadIcon />
                </CSVLink>
              </SmallButton>
            </Tooltip>
          </React.Fragment>
        }
        title="Campaigns"
      />

      <Paper>
        <TableContainer>
          <Box sx={{ width: "100%" }}>
            {campaigns.status === "loading" ? <LinearProgress /> : null}
          </Box>
          <Table ref={campaignsRef}>
            <TableHead>
              <TableRow>
                <TableCell component="th">Campaign ID</TableCell>
                <TableCell component="th">Campaign name</TableCell>
                <TableCell component="th" align="right">
                  Impressions
                </TableCell>
                <TableCell component="th" align="right">
                  Clicks
                </TableCell>
                <TableCell component="th" align="right">
                  Spends
                </TableCell>
                <TableCell component="th" align="right">
                  CPM ($)
                </TableCell>
                <TableCell component="th" align="right">
                  CPC ($)
                </TableCell>
                <TableCell component="th" align="right">
                  CTR (%)
                </TableCell>
                <TableCell component="th" align="right">
                  Conversions
                </TableCell>
                <TableCell component="th" align="right">
                  &gt; CR
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns?.campaigns?.stats
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((campaign, campaignIdx) => (
                  <TableRow key={campaign.id}>
                    {/* Campaign ID */}
                    <TableCell component="td"> {campaign?.id} </TableCell>

                    {/* Campaign Name */}
                    <TableCell component="td" component="th" scope="row">
                      <Link
                        to={"/campaigns/campaign-details/" + campaign.id}
                        style={{ color: blue[500], textDecoration: "none" }}
                      >
                        {cleanCampaignName(campaign.name)}
                      </Link>
                    </TableCell>

                    {/* Impressions */}
                    <TableCell component="td" align="right">
                      {getImpressions(
                        campaign.spent,
                        campaigns.campaigns.multipliers.spends,
                        campaigns.campaigns.multipliers.cpm,
                        campaign.cpm
                      )}
                    </TableCell>

                    {/* Clicks */}
                    <TableCell component="td" align="right">
                      {getClicks(
                        campaign.clicks,
                        campaigns.campaigns.multipliers.ctr
                      )}
                    </TableCell>

                    {/* Spends */}
                    <TableCell component="td" component="td" align="right">
                      {"$ "}
                      {getSpends(
                        campaign.spent,
                        campaigns.campaigns.multipliers.spends
                      )}
                    </TableCell>

                    {/* CPM */}
                    <TableCell component="td" align="right">
                      {"$ "}
                      {getCpm(
                        campaign.cpm,
                        campaigns.campaigns.multipliers.cpm,
                        campaign.clicks,
                        campaigns.campaigns.multipliers.ctr,
                        campaign.spent,
                        campaigns.campaigns.multipliers.spends,
                        campaign.impressions
                      )}
                    </TableCell>

                    {/* CPC */}
                    <TableCell component="td" align="right">
                      {"$ "}
                      {getCpc(
                        campaign.spent,
                        campaigns.campaigns.multipliers.spends,
                        campaign.clicks,
                        campaigns.campaigns.multipliers.ctr
                      )}
                    </TableCell>

                    {/* CTR */}
                    <TableCell component="td" align="right">
                      {getCtr(
                        campaign.clicks,
                        campaign.spent,
                        campaigns.campaigns.multipliers.spends,
                        campaign.cpm,
                        campaigns.campaigns.multipliers.ctr,
                        campaigns.campaigns.multipliers.cpm
                      )}
                      {" %"}
                    </TableCell>

                    {/* Conversions */}
                    <TableCell component="td" align="right">
                      {campaign.conversions}
                    </TableCell>

                    {/* CR */}
                    <TableCell component="td" align="right">
                      {campaign.cr}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={
            campaigns?.campaigns?.stats?.length
              ? campaigns?.campaigns?.stats?.length
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Card>
  );
}

export default DashboardTable;
